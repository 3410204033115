<template>
  <v-container fluid >
    <h3>Education</h3>
      <v-timeline dense >
        <v-timeline-item small >
            <v-row>
              <v-col>
                <h5>2011 - 2013</h5>
                <h4>Apprenticeship as specialist for application development</h4>
                <h5>Graduation: 71/100</h5>
              </v-col>
            </v-row>
        </v-timeline-item>
        <v-timeline-item small >
            <v-row>
              <v-col>
                <h5>2010 - 2011</h5>
                <h4>Scientific Gymnasium “Voltaire” ( Italy ) </h4>
                <h5>Graduation: 70/100</h5>
              </v-col>
            </v-row>
        </v-timeline-item>
        <v-timeline-item small >
            <v-row>
              <v-col>
                <h5>2008 - 2010</h5>
                <h4>Scientific Gymnasium Max-Plank Gymnasium München</h4>
              </v-col>
            </v-row>
        </v-timeline-item>
        <v-timeline-item small >
            <v-row>
              <v-col>
                <h5>09.2008</h5>
                <h4>Moved to Munich</h4>
              </v-col>
            </v-row>
        </v-timeline-item>
        <v-timeline-item small >
            <v-row>
              <v-col>
                <h5>2006 - 2008</h5>
                <h4>Scientific Gymnasium “Niccoló Copernico” in Udine</h4>
              </v-col>
            </v-row>
        </v-timeline-item>
        <v-timeline-item small>
            <v-row>
              <v-col>
                <h5>2002 - 2005</h5>
                <h4>middle school “Ignazio Silone” in Rome</h4>
              </v-col>
            </v-row>
        </v-timeline-item>
        <v-timeline-item small >
            <v-row>
              <v-col>
                <h5>1997 - 2002</h5>
                <h4>primary school “Walt Disney” in Rome</h4>
              </v-col>
            </v-row>
        </v-timeline-item>
      </v-timeline>
  </v-container>
</template>

<script>
export default {
  name: 'education'
}
</script>

<style scoped>

</style>
